import React from "react"
import Slider from "react-slick"
import Image from "../images/Fine-Logo.png"

// importing brands images
import brand1 from '../images/brands/brand1.png'
import brand2 from '../images/brands/brand2.png'
import brand3 from '../images/brands/brand3.png'
import brand4 from '../images/brands/brand4.png'
import brand5 from '../images/brands/brand5.png'
import brand6 from '../images/brands/brand6.png'
import brand7 from '../images/brands/brand7.png'
import brand8 from '../images/brands/brand8.png'
import brand9 from '../images/brands/brand9.png'
import brand10 from '../images/brands/brand10.png'
import brand11 from '../images/brands/brand11.png'
import brand12 from '../images/brands/brand12.png'
import brand13 from '../images/brands/brand13.png'
import brand14 from '../images/brands/brand14.png'
import brand15 from '../images/brands/brand15.png'
import brand16 from '../images/brands/brand16.png'

const arr = [
  {img : brand1}, {img: brand2},{img: brand3}, {img: brand4} , {img: brand5},
  {img : brand6}, {img: brand7},{img: brand8}, {img: brand9} , {img: brand10},
  {img: brand11},{img: brand12}, {img: brand13} , {img: brand14},
  {img : brand15}, {img : brand16}
  ]

function OurBrands() {

  var settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 5,
    slidesToScroll: 2,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          arrows: false,

          dots:true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  }

  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {arr.map((name, index) => (
              <a href="#" className="product-link">
                <div key={index} className="product-slider-content bg-transparent no-shadow">
                  <div className="product-img client_logo_image">
                    <img src={name.img} alt="product image" width="100%"/>
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
      </div>
    </>
  )
}

export default OurBrands
