import React from "react"

function FooterBottom() {
  return(
    <div className="footer-call">
      <div className="row w-100 m-0">
        <div className="col-lg-6 container bg-primary-blue text-light py-2">
          <div className="footer-content d-flex justify-content-center align-items-center">
            <div className="mr-3"><span className="fab fa-quote-left"></span></div>
            <div className="">
              <p className="footer-tittle m-0 first-head">Get your free quote about tools</p>
              <p className="footer-content m-0 second-head">We're here to make your daily work easy</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 container bg-primary-red text-light py-2">
          <div className="footer-content d-flex justify-content-center align-items-center">
            <div className="mr-3">
              <span className="fab fa-newspaper-o"></span>
            </div>
            <div className="">
              <p className="footer-tittle m-0 second-head">For Orders & Latest Offers</p>
              <p className="footer-content m-0 first-head">0332 280 400 | +94 76 968 0400</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterBottom