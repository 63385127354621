import React from "react"

function Service() {
  return(
    <>
      {/*delivery*/}
      <div className='col-12 mt-5 no-gutters p-0'>
        <div className='col-lg-6 d-flex flex-column-reverse flex-lg-row align-items-center justify-content-center justify-content-lg-end'>
          <div className="service-info mr-lg-4">
            <p className='text-lg-right text-center mt-2 mt-lg-0 delivery-text'>
              Delivery
            </p>
            <p className='text-lg-right text-center'>
              We provide faster and efficient covering the entire nation. Orders are processed on time to make sure that orders reach as faster as possible.
            </p>
          </div>
          <div className="service-icon delivery-bg">
            <span className="fas fa-truck"></span>
          </div>
        </div>
      </div>

      {/*product knowledge*/}
      <div className='col-12 mt-5 mt-lg-0 d-flex justify-content-end no-gutters p-0'>
        <div className='col-lg-6 d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-start' >
          <div className="service-icon product-bg">
            <span className="fas fa-graduation-cap"></span>
          </div>
          <div className="service-info ml-lg-4 mt-2 mt-lg-0">
            <p className='text-lg-left text-center product-text'>
              Product knowledge
            </p>
            <p className='text-lg-left text-center'>
              Our technical sales staffs members are imparted with product knowledge on latest and state of the art tools and machineries thus make your purchases meaningful.
            </p>
          </div>
        </div>
      </div>

      {/*Warranty*/}
      <div className='col-12 mt-5 mt-lg-0 no-gutters p-0'>
        <div className='col-lg-6 d-flex flex-column-reverse flex-lg-row align-items-center justify-content-center justify-content-lg-end'>
          <div className="service-info mr-lg-4">
            <p className='text-lg-right text-center mt-2 mt-lg-0 waranty-text'>
              Warranty
            </p>
            <p className='text-lg-right text-center'>
              We assure warranty for our products with no additional charges to prevent our customers from buying cheaper non-warranty products.
            </p>
          </div>
          <div className="service-icon waranty-bg">
            <span className="fas fa-shield"></span>
          </div>
        </div>
      </div>

      {/*After sales service*/}
      <div className='col-12 mt-5 mt-5 mt-lg-0 d-flex justify-content-end no-gutters p-0'>
        <div className='col-lg-6 d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-start'>
          <div className="service-icon knowledge-bg">
            <span className="fas fa-support"></span>
          </div>
          <div className="service-info ml-lg-4 mt-2 mt-lg-0">
            <p className='text-lg-left text-center knowledge-text'>
              After sales service
            </p>
            <p className='text-lg-left text-center'>
              Machineries and tools are provided with after sales services with a comprehensive and instantly available spares and accessories
            </p>
          </div>
        </div>
      </div>

      {/*Repair and maintenance*/}
      <div className='col-12 mt-5 mt-lg-0 no-gutters p-0'>
        <div className='col-lg-6 d-flex flex-column-reverse flex-lg-row align-items-center justify-content-center justify-content-lg-end'>
          <div className="service-info mr-lg-4 mt-2 mt-lg-0">
            <p className='text-lg-right text-center customer-text'>
              Repair and maintenance
            </p>
            <p className='text-lg-right text-center'>
              Fine Enterprises has its own fully-fledged service department to ensure rapid repair services.
            </p>
          </div>
          <div className="service-icon customer-bg">
            <span className="fas fa-hand-peace-o"></span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Service