import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Header from "../components/header"
import Service from "../components/services"
import Product from "../components/product"
import Clients from "../components/clients"
import Talk from "../components/KeepInTouch"
import FooterBottom from "../components/footerbottom"
import OurBrands from "../components/customers"
import Loader from "../components/Loader"

const IndexPage = () => (

  <Layout>
    <SEO title="Home" />
    <Header/>
      {/*service section start here*/}
    {/*<section id='serviceSection' className='container'>*/}
    {/*    <div className="fine-headline text-uppercase ">*/}
    {/*        <p className=''>*/}
    {/*            what we have for you*/}
    {/*        </p>*/}
    {/*        <h2>*/}
    {/*            Services*/}
    {/*        </h2>*/}
    {/*    </div>*/}

    {/*    <div className='service-wrapper'>*/}
    {/*        <Service/>*/}
    {/*    </div>*/}
    {/*</section>*/}

      {/*product section start here*/}
      {/*<div className="fine-headline text-uppercase ">*/}
      {/*    <p className=''>*/}
      {/*        Find what you want?*/}
      {/*    </p>*/}
      {/*    <h2>*/}
      {/*        Products*/}
      {/*    </h2>*/}
      {/*</div>*/}
      {/*<section id="productWrapper" className='container'>*/}
      {/*    <Product/>*/}
      {/*</section>*/}

      {/*find more section start here*/}
      {/*<div className="find-us-wrapper  text-center">*/}
      {/*    <div className="container p-4">*/}
      {/*        <h2>*/}
      {/*            Fine enterprises has a progressive experience of over 10 years in*/}
      {/*            importing &  Distributing tools, machineries and accessories*/}
      {/*        </h2>*/}
      {/*        <Link to="/about">Find more</Link>*/}
      {/*    </div>*/}
      {/*</div>*/}

      {/*Client section start here*/}
    {/*  <div className="fine-headline text-uppercase ">*/}
    {/*    <p className=''>*/}
    {/*      What they feel*/}
    {/*    </p>*/}
    {/*    <h2>*/}
    {/*      Clients*/}
    {/*    </h2>*/}
    {/*  </div>*/}
    {/*<section id="productWrapper" className='container'>*/}
    {/*  <Clients/>*/}
    {/*</section>*/}

    {/*get in touch section*/}
    {/*<Talk/>*/}
    <div className="fine-headline text-uppercase ">
      <p className=''>
        Choose one
      </p>
      <h2>
        Category
      </h2>
    </div>

    {/* <div>
      <ul className="honeycomb" lang="es">
      <li className="honeycomb-cell">
          <img className="honeycomb-cell__image" src="https://cnet2.cbsistatic.com/img/8UmghpDXC7eVtwyWAq7y6-6UKH4=/940x0/2017/03/13/8bb2afb4-fdc1-43b0-9528-2150916ff113/power-tools.jpg"/>
          <div className="honeycomb-cell__title">Agriculture</div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell__image" src="https://www.mactools.com/en-us/Lists/ProductCategoryImageLibrary/PowerTools_AirTools.jpg?Width=560&Height=560"/>
          <div className="honeycomb-cell__title">Carpentry</div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell__image" src="https://images-na.ssl-images-amazon.com/images/I/81ci1D1D4uL._AC_SY355_.jpg"/>
          <div className="honeycomb-cell__title">Masonry</div>
        </li>


        <li className="honeycomb-cell">
          <img className="honeycomb-cell__image" src="https://cnet2.cbsistatic.com/img/8UmghpDXC7eVtwyWAq7y6-6UKH4=/940x0/2017/03/13/8bb2afb4-fdc1-43b0-9528-2150916ff113/power-tools.jpg"/>
          <div className="honeycomb-cell__title">Agriculture</div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell__image" src="https://www.mactools.com/en-us/Lists/ProductCategoryImageLibrary/PowerTools_AirTools.jpg?Width=560&Height=560"/>
          <div className="honeycomb-cell__title">Carpentry</div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell__image" src="https://images-na.ssl-images-amazon.com/images/I/81ci1D1D4uL._AC_SY355_.jpg"/>
          <div className="honeycomb-cell__title">Masonry</div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell__image" src="https://www.costco.co.uk/medias/sys_master/images/hc8/h74/12073948741662.jpg"/>
          <div className="honeycomb-cell__title">Light Engineering</div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell__image" src="https://media.diy.com/is/image/KingfisherDigital/WCMS_sq_img?$bgc=ededed&$fgi=KingfisherDigital/2b7378af9b1adb85d4614621adf4f9a1351b1a73&$PROMO_460_460$"/>
          <div className="honeycomb-cell__title">Industries</div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell__image" src="https://hips.hearstapps.com/pop.h-cdn.co/assets/16/33/1471245310-jigsaw.jpg"/>
          <div className="honeycomb-cell__title">Automobile</div>
        </li>
        <li className="honeycomb-cell">
          <img className="honeycomb-cell__image" src="https://image.dhgate.com/webp/m/0x0/f2/albu/g10/M01/67/25/rBVaWV5tXqiAU444AAVhR7pSZg4169.jpg"/>
          <div className="honeycomb-cell__title">General Hardware</div>
        </li>

        <li className="honeycomb-cell honeycomb__placeholder"></li>
      </ul>
    </div> */}

    <div className='category_wrapper container'>
      <div className="first_line first">
        <div className='honeycomb_item'>
          <div className="inner_div item_1">
            <div className="category_content">Agriculture Tools</div>
          </div>
        </div>
        <div className='honeycomb_item'>
        <div className="inner_div item_2">
        <div className="category_content">Compressor and Air Tool</div>
        </div>
        </div>
        <div className='honeycomb_item third_item'>
        <div className="inner_div item_3">
        <div className="category_content">Power Tools</div>
        </div>
        </div>

      </div>

      <div className="first_line second_line">
        <div className='honeycomb_item'><div className="inner_div item_4">
        <div className="category_content">Lathe Tools</div></div></div>
        <div className='honeycomb_item'><div className="inner_div item_5">
        <div className="category_content">Industrial Tools</div></div></div>
        <div className='honeycomb_item'><div className="inner_div item_6"><div className="category_content">Safety Equipment</div>
          </div></div>
        <div className='honeycomb_item'><div className="inner_div item_7">
        <div className="category_content">Hand Tool</div></div></div>
      </div>

      <div className="first_line third_line">
        <div className='honeycomb_item'><div className="inner_div item_8">
        <div className="category_content">General Tools</div></div></div>
        <div className='honeycomb_item'><div className="inner_div item_9"><div className="category_content">Wood Working Tools</div></div></div>
        <div className='honeycomb_item third_item'><div className="inner_div item_10"> <div className="category_content">Weilding Tools</div></div></div>
      </div>
      
    </div>

    <div className="fine-headline text-uppercase pt-5 ">
      <p className=''>
        What we have
      </p>
      <h2>
        Brands
      </h2>
    </div>

    <section id="clientSlider" className="container">
      <OurBrands/>
    </section>
  </Layout>
)

export default IndexPage
